// sendCertificationStreamInfo
import sendCertificationStreamInfo from "./sendCertificationStreamInfo";

// sendDataStreamInfo
import sendDataStreamInfo from "./sendDataStreamInfo";

// Utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const genInfoRecord = async (isPublic, uid, keypair, text, tdr) => {
  try {
    const timestamp = unixTimestampInSeconds();

    if (tdr) {
      // Certification stream
      const data = await sendCertificationStreamInfo(keypair, text, timestamp, tdr);

      if (data.txid) {
        // Data stream
        const outcome = await sendDataStreamInfo(isPublic, uid, text, data, timestamp, tdr);

        if (outcome.success) {
          console.log(data);
          return {
            inserted: data.inserted,
            txid: data.txid,
          };
        } else {
          return {
            error: outcome.error,
          };
        }
      } else {
        return {
          error: data.error,
        };
      }
    } else {
      // Certification stream
      const data = await sendCertificationStreamInfo(keypair, text, timestamp);

      if (data.txid) {
        // Data stream
        const outcome = await sendDataStreamInfo(isPublic, uid, text, data, timestamp);

        if (outcome.success) {
          console.log(data);
          return {
            inserted: data.inserted,
            txid: data.txid,
          };
        } else {
          return {
            error: outcome.error,
          };
        }
      } else {
        return {
          error: data.error,
        };
      }
    }
  } catch (e) {
    console.error("Error in genInfoRecord:", e.message);

    return {
      error: e.message,
    };
  }
};

export default genInfoRecord;
