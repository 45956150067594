// React
import React, { useState, useEffect } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-pdf
import { Document, Page, pdfjs } from "react-pdf";

// react-xml-viewer
import XMLViewer from "react-xml-viewer";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

// Material UI Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import FileUploadIcon from "@mui/icons-material/FileUpload";

// Components
import CDNFileForm from "./CDNFileForm";
import CDNFileUploadReceipt from "./CDNFileUploadReceipt";
import { Puller } from "ui-components/Puller";
import { LoadingDialog } from "ui-components/LoadingComponent";
import { NewCertConfirm, InvalidFile } from "ui-components/ORFeedbacks";

// Functions
import fileToUint8Array from "utils/fileToUint8Array";
import uploadFile from "storage/uploadFile";
import genCDNFileRecord from "generator/CDNFileGenerator/genCDNFileRecord";
import getUserContacts from "UserOperations/getUserContacts";

// SafeTwin
import { calculateSHA256, toHex } from "SafeTwin/crypto/cryptolibsodium";

// initialDocumentProperties
import { initialDocumentProperties } from "./initialDocumentProperties";

// Firebase
import { db } from "config/firebase";
import { onSnapshot, doc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const MAX_FILE_SIZE = 25 * 1024 * 1024;

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CDNFileGenerator = ({ tag, types, setTypes, open, setOpen, handleOpenCertificationSuccessful, handleOpenCertificationError }) => {
  const { t } = useTranslation();
  const { user } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [source, setSource] = useState("");
  const [blobURL, setBlobURL] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [xmlContent, setXmlContent] = useState("");
  const [textContent, setTextContent] = useState("");
  const [documentProperties, setDocumentProperties] = useState(initialDocumentProperties);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [confirm, setConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidFile, setIsInvalidFile] = useState(false);
  const [uploadReceipt, setUploadReceipt] = useState(null);
  const [uploadReceiptTXID, setUploadReceiptTXID] = useState(null);
  const [receiptDialogOpen, setReceiptDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  useEffect(() => {
    if (!uploadReceiptTXID || !user) return;

    const unsubscribe = onSnapshot(doc(db, "uploadReceipts", uploadReceiptTXID), async (doc) => {
      if (doc.exists()) {
        const receiptData = doc.data();
        if (receiptData.creator_uuid === user.uid) {
          const creatorContacts = await getUserContacts(receiptData.creator_uuid);
          const creatorEmail = creatorContacts.email || "N/A";
          setUploadReceipt({ ...receiptData, creatorEmail: creatorEmail });
          setReceiptDialogOpen(true);
        }
      }
    });

    return () => unsubscribe();
  }, [uploadReceiptTXID, user]);

  const handleGenerate = async () => {
    setConfirm(false);
    setIsLoading(true);

    try {
      let downloadURL = "";
      let uploadID = null;

      const uploadResult = await uploadFile(source, fileName, fileType, tag.id);
      downloadURL = uploadResult.downloadURL;
      uploadID = uploadResult.uploadID;

      if (downloadURL && uploadID) {
        const dataBody = {
          source,
          downloadURL,
          uploadID,
          fileName,
          fileType,
          documentProperties,
        };

        const result = await genCDNFileRecord(user.uid, dataBody, tag.id);

        if (result.success) {
          setUploadReceiptTXID(result.txid);
          handleOpenCertificationSuccessful();
          if (types && !types.includes("CDNFile")) {
            setTypes([...types, "CDNFile"]);
          }
        } else {
          handleOpenCertificationError();
        }
      } else {
        console.error("Invalid file format.");
        setIsInvalidFile(true);
        resetFileInput();
      }
    } catch (error) {
      console.error("Error in handleGenerate:", error.message);
      handleOpenCertificationError();
    } finally {
      setIsLoading(false);
      handleReset();
    }
  };

  const handleFileChange = async (target) => {
    if (target.files && target.files.length) {
      const file = target.files[0];

      if (file.size > MAX_FILE_SIZE) {
        console.error("File size exceeds 25MB limit.");
        setErrorMessage("File size exceeds the 25MB limit.");
        setIsInvalidFile(true);
        resetFileInput();
        return;
      }

      const validExtensions = ["pdf", "xml", "txt", "csv", "png"];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const mimeType = file.type;

      if (
        validExtensions.includes(fileExtension) &&
        (mimeType.includes("application/pdf") ||
          mimeType.includes("text/xml") ||
          mimeType.includes("application/xml") ||
          mimeType.includes("text/plain") ||
          mimeType.includes("text/csv") ||
          mimeType.includes("image/png"))
      ) {
        const fileByteArray = await fileToUint8Array(file);
        const blobURL = URL.createObjectURL(file);
        setSource(fileByteArray);
        setBlobURL(blobURL);
        setFileName(file.name);
        setFileType(file.type);
        setDocumentProperties((prevProps) => ({
          ...prevProps,
          nomeDelDocumento: file.name,
          tipologiaDocumentale: tag.tipologiaDocumentale || "N/A",
          impronta: toHex(calculateSHA256(fileByteArray)),
          algoritmo: "SHA-256",
          formatoDocumento: file.type,
          prodottoSoftwareNome: "Trusted Operations",
          prodottoSoftwareVersione: "v1.0.0",
          prodottoSoftwareProduttore: "Armilis SA",
        }));
      } else {
        console.error(`Invalid file format: ${fileExtension}. Allowed formats: pdf, xml, txt, csv, png.`);
        setErrorMessage(`Invalid file format: ${fileExtension}. Allowed formats: pdf, xml, txt, csv, png.`);
        setIsInvalidFile(true);
        resetFileInput();
      }
    }
  };

  const resetFileInput = () => {
    const fileInput = document.getElementById("new-upload-home-button");
    if (fileInput) {
      fileInput.value = null;
    }
  };

  const handleResetFileData = () => {
    setSource("");
    setBlobURL("");
    URL.revokeObjectURL(blobURL);
    setFileName("");
    setFileType("");
    setDocumentProperties(initialDocumentProperties);
    setIsInvalidFile(false);
    setSelectedFile(null);
  };

  const handleReset = () => {
    handleResetFileData();
    setOpen(false);
    resetFileInput();
  };

  const handleResetUploadReceipt = () => {
    setUploadReceiptTXID(null);
    setUploadReceipt(null);
    setReceiptDialogOpen(false);
  };

  const handleFileClick = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const fileType = blob.type;

      if (fileType === "application/pdf") {
        setSelectedFile(fileUrl);
      } else if (fileType === "text/xml" || fileType === "application/xml") {
        const xmlText = await blob.text();
        setXmlContent(xmlText);
        setSelectedFile(fileUrl);
      } else if (fileType === "text/plain" || fileType === "text/csv") {
        const textContent = await blob.text();
        setTextContent(textContent);
        setSelectedFile(fileUrl);
      } else if (fileType === "image/png") {
        setSelectedFile(fileUrl);
      }
    } catch (error) {
      console.error("Error fetching and parsing file:", error);
    }
  };

  const handleCloseDialogPDF = () => {
    setSelectedFile(null);
    setCurrentPage(1);
  };

  const handleCloseDialogImage = () => {
    setSelectedFile(null);
  };

  const handleCloseDialogXML = () => {
    setSelectedFile(null);
    setXmlContent("");
  };

  const handleCloseDialogText = () => {
    setSelectedFile(null);
    setTextContent("");
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderFileDialogs = () => {
    if (!selectedFile) return null;

    switch (fileType) {
      case "application/pdf":
        return (
          <Dialog open onClose={handleCloseDialogPDF} maxWidth="sm" fullWidth>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton sx={{ color: "red" }} onClick={handleCloseDialogPDF} aria-label="close">
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Document file={selectedFile} onLoadSuccess={handleDocumentLoadSuccess}>
              <Page pageNumber={currentPage} width={isMobile ? 300 : 600} renderAnnotationLayer={false} renderTextLayer={false} />
            </Document>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} aria-label="previous page">
                <KeyboardArrowLeftOutlinedIcon />
              </IconButton>
              <Typography>{`${currentPage} / ${numPages}`}</Typography>
              <IconButton onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === numPages} aria-label="next page">
                <KeyboardArrowRightOutlinedIcon />
              </IconButton>
            </Box>
          </Dialog>
        );

      case "text/xml":
      case "application/xml":
        return (
          <Dialog open onClose={handleCloseDialogXML} maxWidth="sm" fullWidth>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton sx={{ color: "red" }} onClick={handleCloseDialogXML} aria-label="close">
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
              <XMLViewer xml={xmlContent} />
            </Box>
          </Dialog>
        );

      case "text/plain":
        return (
          <Dialog open onClose={handleCloseDialogText} maxWidth="sm" fullWidth>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton sx={{ color: "red" }} onClick={handleCloseDialogText} aria-label="close">
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography>{textContent}</Typography>
            </Box>
          </Dialog>
        );

      case "text/csv":
        const rows = textContent.split("\n").map((row) => row.split(","));
        return (
          <Dialog open onClose={handleCloseDialogText} maxWidth="sm" fullWidth>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton sx={{ color: "red" }} onClick={handleCloseDialogText} aria-label="close">
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
              <table>
                <tbody>
                  {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Dialog>
        );

      case "image/png":
        return (
          <Dialog open onClose={handleCloseDialogImage} maxWidth="sm" fullWidth>
            <Box sx={{ position: "relative" }}>
              <IconButton
                sx={{
                  position: "absolute",
                  top: "2%",
                  right: "2%",
                  color: "red",
                }}
                onClick={handleCloseDialogImage}
                aria-label="close"
              >
                <CloseOutlinedIcon />
              </IconButton>
              <img src={selectedFile} alt="Document" style={{ width: "100%" }} />
            </Box>
          </Dialog>
        );

      default:
        return null;
    }
  };

  return isLoading ? (
    <LoadingDialog open={isLoading} />
  ) : (
    <>
      {isMobile ? (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={handleReset}
          onOpen={() => setOpen(true)}
          sx={{
            "& .MuiDrawer-paper": {
              width: "100%",
              height: "90%",
              borderTopLeftRadius: "4%",
              borderTopRightRadius: "4%",
            },
          }}
        >
          <Puller />
          <Box sx={{ p: "5%" }}>
            <Grid container alignItems="center" justifyContent="center" mt="5%" mb="10%">
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  {t("upload")}
                </Typography>
              </Grid>
            </Grid>
            <>
              <CDNFileForm
                isMobile={isMobile}
                source={source}
                blobURL={blobURL}
                fileName={fileName}
                documentProperties={documentProperties}
                setDocumentProperties={setDocumentProperties}
                handleFileChange={handleFileChange}
                handleFileClick={handleFileClick}
              />
              <Grid container spacing={1} mt="5%">
                <Grid item xs={12}>
                  <Button fullWidth variant="contained" startIcon={<FileUploadIcon />} disabled={isLoading || !source || !fileName || !fileType} onClick={() => setConfirm(true)}>
                    {t("upload")}
                  </Button>
                </Grid>
              </Grid>
            </>
          </Box>
        </SwipeableDrawer>
      ) : (
        <Dialog open={open} onClose={handleReset} maxWidth="lg" fullWidth>
          <DialogTitle>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  {t("upload")}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleReset} edge="end" sx={{ color: "red" }}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <CDNFileForm
              isMobile={isMobile}
              source={source}
              blobURL={blobURL}
              fileName={fileName}
              documentProperties={documentProperties}
              setDocumentProperties={setDocumentProperties}
              handleFileChange={handleFileChange}
              handleFileClick={handleFileClick}
            />
            <Grid item container xs={12} spacing={1} mt="3%">
              <Grid item xs={12}>
                <Button fullWidth variant="contained" startIcon={<FileUploadIcon />} disabled={isLoading || !source || !fileName || !fileType} onClick={() => setConfirm(true)}>
                  {t("upload")}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}

      {confirm && <NewCertConfirm confirm={confirm} setConfirm={setConfirm} handleGenerate={handleGenerate} />}

      {isInvalidFile && <InvalidFile open={isInvalidFile} setOpen={setIsInvalidFile} message={errorMessage} />}

      {uploadReceipt && <CDNFileUploadReceipt receiptDialogOpen={receiptDialogOpen} handleResetUploadReceipt={handleResetUploadReceipt} uploadReceipt={uploadReceipt} />}

      {renderFileDialogs()}
    </>
  );
};

export default CDNFileGenerator;
