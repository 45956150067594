// Firebase
import { db } from "config/firebase";
import { collection, setDoc, doc } from "firebase/firestore";

// UserOperations
import addSignature from "UserOperations/addSignature";

// A ---------------------------------------------------------------------- M

const sendDataStreamDoc = async (isPublic, uid, downloadURL, comment, data, timestamp, tdr) => {
  try {
    // Record transaction id (txid)
    const txid = data.txid;

    // Create new record in the recordsdata collection, using txid as document id
    const recordRef = doc(db, "recordsdata", txid);

    // Set type
    const type = "doc";

    // Set the new record
    await setDoc(recordRef, {
      txid: txid,
      type: type,
      version: 1,
      ...(tdr && { tdr: tdr }),
      creator_uuid: uid,
      data: {
        attachment: downloadURL,
        comment: comment,
        timestamp: timestamp,
      },
      timestamp: timestamp,
    });

    // Create record link to tagsdata collection, using txid as foreign key. This is done just in case there is a linked databox, otherwise this is the case of a free record
    if (tdr) {
      // Tag reference
      const tagRef = doc(db, "tagsdata", tdr);

      // Signatures collection reference related to the tag
      const recordsRef = collection(tagRef, "signatures");

      // Set new record link to the signatures collection
      await setDoc(doc(recordsRef, txid), {
        txid: txid,
        type: type,
        creator_uuid: uid,
        db_id: 1,
        public: isPublic,
        timestamp: timestamp,
      });
    }

    // Add signature to mysigs subcollection of userdata collection
    tdr ? await addSignature(uid, txid, timestamp, type, tdr) : await addSignature(uid, txid, timestamp, type);
    return {
      success: true,
    };
  } catch (e) {
    console.error("Error in sending data stream doc:", e.message);
    return {
      error: e.message,
    };
  }
};

export default sendDataStreamDoc;
