// Firebase
import { doc, collection, getDocs } from "firebase/firestore";
import { db } from "config/firebase";

// RecordOperations
import fetchSignature from "../RecordOperations/fetchSignature";

// TagOperations
import getTagInfo from "TagOperations/getTagInfo";

// A ---------------------------------------------------------------------- M

const fetchSignatures = async (uid) => {
  try {
    const userRef = doc(db, "userdata", uid);
    const mysigsRef = collection(userRef, "mysigs");
    const snapshot = await getDocs(mysigsRef);

    const signaturePromises = snapshot.docs.map(async (sig) => {
      try {
        const signature = await fetchSignature(sig.id);

        if (!signature.tdr) {
          return signature;
        }

        const tagInfo = await getTagInfo(signature.tdr);
        return {
          ...signature,
          name: tagInfo?.name || "",
        };
      } catch (error) {
        console.error(`Error fetching signature or tag info for sig ID ${sig.id}:`, error.message);
        return undefined;
      }
    });

    const signatureResults = await Promise.all(signaturePromises);
    const signatures = signatureResults.filter((obj) => obj !== undefined);
    return signatures;
  } catch (error) {
    console.error(`Error in fetchSignatures for uid ${uid}:`, error.message);
    return [];
  }
};

export default fetchSignatures;
