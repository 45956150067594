// React
import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Tooltip,
  Checkbox,
  Divider,
} from "@mui/material";

// Material UI Icons
import {
  FirstPageOutlined as FirstPageOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  LastPageOutlined as LastPageOutlinedIcon,
  VisibilityOff as VisibilityOffIcon,
  Visibility as VisibilityIcon,
  Add as AddIcon,
} from "@mui/icons-material";

// TagOperations
import doesTagExist from "TagOperations/doesTagExist";

// DatakeyOperations
import setCanCreateDataboxFlag from "DatakeyOperations/setCanCreateDataboxFlag";
import unsetCanCreateDataboxFlag from "DatakeyOperations/unsetCanCreateDataboxFlag";

// Utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const DataKeysTableHead = () => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "name", label: t("name") },
      { id: "timestamp", label: t("creation_date") },
      { id: "id", label: t("id") },
      { id: "value", label: t("value") },
      { id: "canCreateDatabox", label: t("can_create_databox") },
    ],
    [t]
  );

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.id !== "canCreateDatabox" ? "left" : "right"}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const DataKeysTableToolbar = ({ handleOpen }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={10}>
        <Typography variant="h6" fontWeight="bold">
          {t("datakeys")}
        </Typography>
      </Grid>
      <Grid item container xs={12} md={2} justifyContent="flex-end">
        <Button variant="contained" fullWidth sx={{ height: "100%" }} onClick={handleOpen} startIcon={<AddIcon />}>
          {t("new_datakey")}
        </Button>
      </Grid>
    </Grid>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {isRtl ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {isRtl ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const DataRow = ({ datakey }) => {
  const { t } = useTranslation();

  const [showDataKey, setShowDataKey] = useState(false);
  const [canCreate, setCanCreate] = useState(datakey.canCreateDatabox || false);
  const [tagExists, setTagExists] = useState(false);

  useEffect(() => {
    const checkTagExistence = async () => {
      const exists = await doesTagExist(datakey.id);
      setTagExists(exists);
    };

    checkTagExistence();
  }, [datakey.id]);

  const toggleDataKeyVisibility = () => {
    setShowDataKey(!showDataKey);
  };

  const handleCheckboxChange = async (event) => {
    const isChecked = event.target.checked;
    setCanCreate(isChecked);
    if (isChecked) {
      await setCanCreateDataboxFlag(datakey.id);
    } else {
      await unsetCanCreateDataboxFlag(datakey.id);
    }
  };

  return (
    <TableRow sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" } }}>
      <TableCell align="left">{datakey.name || "N/A"}</TableCell>
      <TableCell align="left">{datakey.created_on ? convertTimestamp(datakey.created_on) : "N/A"}</TableCell>
      <TableCell align="left">
        {tagExists ? (
          <Link to={`/${datakey.id}`} style={{ textDecoration: "none" }}>
            {datakey.id || "N/A"}
          </Link>
        ) : (
          datakey.id || "N/A"
        )}
      </TableCell>
      <TableCell align="left" sx={{ minWidth: "180px", maxWidth: "180px" }} size="small">
        <span style={{ marginRight: "10px" }}>{showDataKey ? datakey.datakey : "••••••"}</span>
        <Tooltip title={showDataKey ? t("hide") : t("show")} placement="top">
          <IconButton onClick={toggleDataKeyVisibility} color="primary">
            {showDataKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell align="right" size="small">
        <Checkbox checked={canCreate} onChange={handleCheckboxChange} inputProps={{ "aria-label": "controlled" }} />
      </TableCell>
    </TableRow>
  );
};

const DataKeysTable = ({ dataKeys, handleOpen }) => {
  console.log(dataKeys);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataKeys.length) : 0;

  return (
    <Box>
      <DataKeysTableToolbar handleOpen={handleOpen} />
      <br />
      <Divider />
      <TableContainer>
        <Table>
          <DataKeysTableHead />
          <TableBody>
            {(rowsPerPage > 0 ? dataKeys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : dataKeys).map((datakey) => {
              return <DataRow key={datakey.id} datakey={datakey} />;
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
          {!isMobile && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                  colSpan={6}
                  count={dataKeys.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={({ from, to, count }) =>
                    t("displayed_rows", {
                      from,
                      to,
                      count: count !== -1 ? count : `more than ${to}`,
                    })
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
        {isMobile && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <TablePaginationActions count={dataKeys.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />
          </Box>
        )}
      </TableContainer>
    </Box>
  );
};

DataKeysTable.propTypes = {
  dataKeys: PropTypes.array.isRequired,
  handleOpen: PropTypes.func.isRequired,
};

export default DataKeysTable;
