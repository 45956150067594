// React
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  IconButton,
  useTheme,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  useMediaQuery,
  Tooltip,
} from "@mui/material";

// Material UI Icons
import {
  FirstPageOutlined as FirstPageOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  LastPageOutlined as LastPageOutlinedIcon,
  ExpandMore as ExpandMoreIcon,
  Verified as VerifiedIcon,
} from "@mui/icons-material";

// Functions
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const ConfigTableHead = () => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "id", label: t("id") },
      { id: "timestamp", label: t("generation_date") },
      { id: "creator", label: t("creator") },
      { id: "target", label: t("target") },
      { id: "visibility", label: t("visibility") },
      { id: "actions", label: t("actions") },
    ],
    [t]
  );

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.id !== "actions" ? "left" : "right"}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const ConfigTableToolbar = () => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={10}>
        <Typography variant="h6" fontWeight="bold">
          {t("configuration_history")}
        </Typography>
      </Grid>
    </Grid>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {isRtl ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {isRtl ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const RowWithAccordion = ({ record, checkVerification, isLastRecentRecord }) => {
  const { t } = useTranslation();

  const jsonConfig = JSON.parse(record.data.jsonconfig);

  const [expanded, setExpanded] = useState(isLastRecentRecord);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <TableRow key={record.id}>
        <TableCell align="left">{record.txid || "7af3ab"}</TableCell>
        <TableCell align="left">{convertTimestamp(record.data.timestamp)}</TableCell>
        <TableCell align="left">{record.creator_email || "N/A"}</TableCell>
        <TableCell align="left">{record.data.target || "N/A"}</TableCell>
        <TableCell align="left">{record.public ? "Public" : "Private"}</TableCell>
        <TableCell align="right" size="small">
          <Tooltip title={t("verify")} placement="top">
            <IconButton sx={{ color: "green" }} onClick={() => checkVerification(record)}>
              <VerifiedIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={12}>
          <Accordion expanded={expanded} onChange={toggleExpand}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography color="gray">Configuration Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                {Object.entries(jsonConfig).map(([key, value]) => (
                  <div key={key}>
                    <Typography variant="body2">
                      <b>{key}</b>: {value}
                    </Typography>
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        </TableCell>
      </TableRow>
    </>
  );
};

const HistoryConfig = ({ records, checkVerification }) => {
  console.log(records);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - records.length) : 0;

  return (
    <Box>
      <ConfigTableToolbar />
      <br />
      <Divider />
      <TableContainer>
        <Table>
          <ConfigTableHead />
          <TableBody>
            {(rowsPerPage > 0 ? records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : records).map((record, index) => {
              const isLastRecentRecord = index === 0 && page === 0;
              return <RowWithAccordion key={`${record.id}-${isLastRecentRecord}`} record={record} checkVerification={checkVerification} isLastRecentRecord={isLastRecentRecord} />;
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {!isMobile && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                  colSpan={6}
                  count={records.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={({ from, to, count }) =>
                    t("displayed_rows", {
                      from,
                      to,
                      count: count !== -1 ? count : `more than ${to}`,
                    })
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
        {isMobile && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <TablePaginationActions count={records.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />
          </Box>
        )}
      </TableContainer>
    </Box>
  );
};

HistoryConfig.propTypes = {
  records: PropTypes.array.isRequired,
  checkVerification: PropTypes.func.isRequired,
};

export default HistoryConfig;
