// React
import React, { useState, useEffect, forwardRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Drawer from "@mui/material/Drawer";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";

// Material UI Icons
import SearchOffOutlinedIcon from "@mui/icons-material/SearchOffOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

// Components
import TagToolbar from "ui-components/DataboxManagement/TagToolbar";
import CertificationMenu from "ui-components/CertificationManagement/CertificationMenu";
import TagConfigure from "ui-components/DataboxManagement/TagConfigure";
import RequestsTable from "ui-components/CertificationManagement/RequestsTable";
import AggregatedViewer from "ui-components/DataTypeManagement/History/AggregatedViewer";
import DocViewer from "ui-components/DataTypeManagement/Doc/DocViewer";
import ImageViewer from "ui-components/DataTypeManagement/Image/ImageViewer";
import InfoViewer from "ui-components/DataTypeManagement/Info/InfoViewer";
import ConfigViewer from "ui-components/DataTypeManagement/Config/ConfigViewer";
import MpsViewer from "ui-components/DataTypeManagement/Mps/MpsViewer";
import SensorDataAggregatedViewer from "ui-components/DataTypeManagement/SensorDataAggregated/SensorDataAggregatedViewer";
import LikeSirtiViewer from "ui-components/DataTypeManagement/Custom/LikeSirti/LikeSirtiViewer";
import CDNFileViewer from "ui-components/DataTypeManagement/CDNFile/CDNFileViewer";
import DocGenerator from "ui-components/DataTypeManagement/Doc/DocGenerator";
import ImageGenerator from "ui-components/DataTypeManagement/Image/ImageGenerator";
import InfoGenerator from "ui-components/DataTypeManagement/Info/InfoGenerator";
import ConfigGenerator from "ui-components/DataTypeManagement/Config/ConfigGenerator";
import CDNFileGenerator from "ui-components/DataTypeManagement/CDNFile/CDNFileGenerator";
import { CertificationSuccessful, CertificationError, DataBoxFound } from "ui-components/ORFeedbacks";

// Firebase
import { db } from "config/firebase";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";

// TagOperations
import getTag from "TagOperations/getTag";
import getTagOwner from "TagOperations/getTagOwner";
import getTagTypes from "TagOperations/getTagTypes";
import getTags from "TagOperations/getTags";
import getTagRecordsCount from "TagOperations/getTagRecordsCount";
import checkIfUserIsFollowingTag from "TagOperations/checkIfUserIsFollowingTag";
import addFollower from "TagOperations/addFollower";
import removeFollower from "TagOperations/removeFollower";

// RecordOperations
import fetchRequests from "RecordOperations/fetchRequests";

// UserOperations
import isUserAuthorizedForDataBox from "UserOperations/isUserAuthorizedForDataBox";
import isUserAuthorizedForDataBoxReadOnly from "UserOperations/isUserAuthorizedForDataBoxReadOnly";
import getUserContacts from "UserOperations/getUserContacts";
import fetchSeenTags from "UserOperations/fetchSeenTags";

// A ---------------------------------------------------------------------- M

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="outlined" {...props} />;
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const CompletenessCheck = ({ tag, recordsCount }) => {
  const isValid = tag === "E4qSNCkLyz";

  return (
    <Paper elevation={4} sx={{ p: "2%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex" alignItems="center">
          {isValid ? (
            <>
              <CheckCircleIcon fontSize="large" sx={{ color: "green", mr: 1 }} />
              <Typography variant="h5" color="green" fontWeight="bold">
                Completeness check successful
              </Typography>
            </>
          ) : (
            <>
              <ErrorIcon fontSize="large" sx={{ color: "red", mr: 1 }} />
              <Typography variant="h5" color="red" fontWeight="bold">
                Completeness check failed
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" sx={{ color: isValid ? "green" : "red" }} fontWeight="bold">
            {isValid ? recordsCount : recordsCount - 2}/{recordsCount} records found.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="h6">{isValid ? "No extra missing point with respect to the certifications registry" : "Some missing points with respect to the certifications registry"}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

const Tag = () => {
  const { user, conservSostL1, canSign, seedflow } = UserAuth();
  const { tag } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [currentTag, setCurrentTag] = useState({});
  const [recordsCount, setRecordsCount] = useState();
  const [requests, setRequests] = useState();
  const [request, setRequest] = useState();
  const [types, setTypes] = useState([]);
  const [seentag, setSeentag] = useState();
  const [tagExist, setTagExist] = useState(false);
  const [tagNotExist, setTagNotExist] = useState(false);
  const [tagConf, setTagConf] = useState(false);
  const [openDocGen, setOpenDocGen] = useState(false);
  const [openImageGen, setOpenImageGen] = useState(false);
  const [openInfoGen, setOpenInfoGen] = useState(false);
  const [openUploadGen, setOpenUploadGen] = useState(false);
  const [openConfigurationGen, setOpenConfigurationGen] = useState(false);
  const [openCertificationSuccessful, setOpenCertificationSuccessful] = useState(false);
  const [openCertificationError, setOpenCertificationError] = useState(false);
  const [isTagGroupMember, setIsTagGroupMember] = useState(false);
  const [isTagGroupMemberReadOnly, setIsTagGroupMemberReadOnly] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handlePopstate = () => {
      if (tagNotExist) {
        handleTagNotExist();
      } else if (tagConf) {
        handleCloseTagConf();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [tagNotExist, tagConf]);

  useEffect(() => {
    const fetchTagInfo = async () => {
      const isTagGroupMember = await isUserAuthorizedForDataBox(tag, user.uid);
      const isTagGroupMemberReadOnly = await isUserAuthorizedForDataBoxReadOnly(tag, user.uid);
      const currentTag = await getTag(tag);
      setIsTagGroupMember(isTagGroupMember);
      setIsTagGroupMemberReadOnly(isTagGroupMemberReadOnly);
      setCurrentTag(currentTag);
    };

    const checkIsFollowing = async () => {
      const following = await checkIfUserIsFollowingTag(user.uid, tag);
      setIsFollowing(following);
    };

    const fetchDataboxRecordsCount = async () => {
      const recordsCount = await getTagRecordsCount(tag);
      setRecordsCount(recordsCount);
    };

    const fetchTagRequests = async () => {
      const tagOwner = await getTagOwner(tag);
      if (user.uid === tagOwner) {
        const requests = await fetchRequests(tag);
        requests.sort((a, b) => b.timestamp - a.timestamp);
        setRequests(requests);
      }
    };

    const getTypes = async () => {
      const types = await getTagTypes(tag);
      const allTypes = [...new Set([...types, ...types])];
      setTypes(allTypes);
    };

    const tagFlow = async () => {
      const tagIDs = await getTags();

      if (tagIDs.includes(tag)) {
        const seentags = await fetchSeenTags(user.uid);
        const seentag = seentags.find((tagObj) => tagObj.id === tag);

        if (seentag) {
          const tagData = await getTag(tag);
          const tagName = tagData.name;
          const tagOwner = await getTagOwner(tag);
          const tagOwnerData = await getUserContacts(tagOwner);
          const tagOwnerEmail = tagOwnerData.email;
          const updatedSeentag = {
            ...seentag,
            name: tagName,
            tagOwner: tagOwner,
            tagOwnerEmail: tagOwnerEmail,
          };

          if (conservSostL1 && tagData.tipologiaDocumentale) {
            updatedSeentag.tipologiaDocumentale = tagData.tipologiaDocumentale;
          }

          setSeentag(updatedSeentag);
          setTagExist(true);

          const tagsdataDocRef = doc(db, "tagsdata", tag);
          const unsubscribeTypesModified = onSnapshot(tagsdataDocRef, (doc) => {
            getTypes();
          });

          return () => {
            unsubscribeTypesModified();
          };
        } else {
          handleTagConfDrawer();
        }
      } else {
        handleTagNotExistDrawer();
      }
    };

    const fetchData = async () => {
      await fetchTagInfo();
      await checkIsFollowing();
      await fetchDataboxRecordsCount();
      await fetchTagRequests();
      await tagFlow();
      setIsLoading(false);
    };

    fetchData();

    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    const tagsdataDocRef = doc(db, "tagsdata", tag);
    const signatureRequestsCollectionRef = collection(tagsdataDocRef, "signature_requests");

    const newAddedQuerySignatureRequests = query(signatureRequestsCollectionRef, where("timestamp", ">=", currentTimestampInSeconds.toString()));
    const newUnsubscribeAddedSignatureRequests = onSnapshot(newAddedQuerySignatureRequests, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          fetchTagRequests();
        }
      });
    });

    const newUnsubscribeUpdatedSignatureRequests = onSnapshot(signatureRequestsCollectionRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "modified") {
          fetchTagRequests();
        }
      });
    });

    return () => {
      newUnsubscribeAddedSignatureRequests();
      newUnsubscribeUpdatedSignatureRequests();
    };
  }, [tag]);

  const openDocGenDrawer = () => {
    setOpenDocGen(true);
    window.history.pushState(null, "");
  };

  const openImageGenDrawer = () => {
    setOpenImageGen(true);
    window.history.pushState(null, "");
  };

  const openInfoGenDrawer = () => {
    setOpenInfoGen(true);
    window.history.pushState(null, "");
  };

  const openUploadGenDrawer = () => {
    setOpenUploadGen(true);
    window.history.pushState(null, "");
  };

  const openConfigurationGenDrawer = () => {
    setOpenConfigurationGen(true);
    window.history.pushState(null, "");
  };

  const handleOpenCertificationSuccessful = () => {
    setOpenCertificationSuccessful(true);
    window.history.pushState(null, "");
  };

  const handleOpenCertificationError = () => {
    setOpenCertificationError(true);
    window.history.pushState(null, "");
  };

  const handleTagNotExistDrawer = () => {
    setTagNotExist(true);
    window.history.pushState(null, "");
  };

  const handleTagConfDrawer = () => {
    setTagConf(true);
    window.history.pushState(null, "");
  };

  const handleTagNotExist = () => {
    setTagNotExist(false);
    navigate("/");
  };

  const handleCloseTagConf = () => {
    setTagConf(false);
    navigate("/");
  };

  const handleFollowToggle = async () => {
    if (isFollowing) {
      await removeFollower(user.uid, tag);
    } else {
      await addFollower(user.uid, tag);
    }
    setIsFollowing(!isFollowing);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [value1, setValue1] = useState(0);

  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
  };

  const handleCertify = (request) => {
    setRequest(request);
    if (request.type === "info") {
      openInfoGenDrawer();
    } else if (request.type === "img") {
      openImageGenDrawer();
    } else if (request.type === "doc") {
      openDocGenDrawer();
    }
  };

  return isLoading ? (
    <Grid item container justifyContent="center" mt="30%">
      <CircularProgress />
    </Grid>
  ) : (
    <>
      {isMobile ? (
        <Drawer
          anchor="bottom"
          open={tagNotExist}
          sx={{ "& .MuiDrawer-paper": { width: "100%" } }}
          PaperProps={{
            sx: { borderTopLeftRadius: "4%", borderTopRightRadius: "4%" },
          }}
        >
          <Box sx={{ p: "6%" }}>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              <SearchOffOutlinedIcon fontSize="large" sx={{ color: "red" }} />
            </Box>
            <Typography variant="h5" color="red" align="center" gutterBottom>
              Not Found
            </Typography>
            <Typography variant="body1" color="red" align="center" gutterBottom>
              {conservSostL1 ? t("archive") : t("databox")}: <b>{tag}</b> does not exist.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1, mt: 2 }}>
              <Button variant="contained" onClick={handleTagNotExist}>
                {t("close")}
              </Button>
            </Box>
          </Box>
        </Drawer>
      ) : (
        <Snackbar open={tagNotExist} autoHideDuration={3000} onClose={handleTagNotExist} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{ mt: { xs: "20%", md: "5%" } }}>
          <Alert onClose={handleTagNotExist} severity="error" sx={{ width: "100%" }}>
            {conservSostL1 ? t("archive") : t("databox")}: <b>{tag}</b> does not exist.
          </Alert>
        </Snackbar>
      )}

      {currentTag && currentTag.name ? (
        <DataBoxFound tag={currentTag} open={tagConf} setOpen={setTagConf} handleClose={handleCloseTagConf} />
      ) : (
        <Drawer
          anchor={isMobile ? "bottom" : "right"}
          open={tagConf}
          onClose={handleCloseTagConf}
          sx={{ "& .MuiDrawer-paper": { width: { xs: "100%", sm: "30%" } } }}
          PaperProps={{
            sx: { borderTopLeftRadius: "4%", borderTopRightRadius: "4%" },
          }}
        >
          {!isMobile && <DrawerHeader />}
          <TagConfigure tag={tag} handleCloseTagConf={handleCloseTagConf} setTagConf={setTagConf} />
        </Drawer>
      )}

      {tagExist && (
        <>
          <Grid item container xs={12} spacing={5}>
            {(isTagGroupMember || isTagGroupMemberReadOnly) && seentag && (
              <Grid item xs={12}>
                <TagToolbar tag={seentag} isFollowing={isFollowing} handleFollowToggle={handleFollowToggle} />
              </Grid>
            )}

            <Grid item xs={12}>
              <Divider sx={{ borderBottomWidth: 1, bgcolor: "black" }} />
            </Grid>

            {tag === "E4qSNCkLyz" && (
              <Grid item container xs={12}>
                <Grid item xs={12} sm={6}>
                  <CompletenessCheck tag={tag} recordsCount={recordsCount} />
                </Grid>
              </Grid>
            )}

            <Grid item container xs={12} spacing={2}>
              <Grid item container spacing={2} alignItems="center">
                <Grid item xs={12} md={10}>
                  <Typography variant="h5" fontWeight="bold">
                    {conservSostL1 ? t("documents") : t("certifications")}
                  </Typography>
                </Grid>
                {!conservSostL1 && isTagGroupMember && canSign && seedflow && currentTag && (
                  <Grid item container xs={12} md={2} justifyContent="flex-end">
                    <CertificationMenu
                      tag={currentTag}
                      openDocGenDrawer={openDocGenDrawer}
                      openImageGenDrawer={openImageGenDrawer}
                      openInfoGenDrawer={openInfoGenDrawer}
                      openConfigurationGenDrawer={openConfigurationGenDrawer}
                    />
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item container xs={12} spacing={5}>
                {types.includes("sensor_data_aggregated") && currentTag && (
                  <Grid item xs={12}>
                    <Paper elevation={3}>
                      <Box p={2}>
                        <SensorDataAggregatedViewer tag={currentTag} isTagGroupMember={isTagGroupMember} />
                      </Box>
                    </Paper>
                  </Grid>
                )}

                {(types.includes("cfg") || types.includes("mps")) && currentTag && (
                  <Grid item xs={12}>
                    <Paper elevation={3}>
                      <Box p={1}>
                        <Grid item container justifyContent="flex-end">
                          <Tabs value={value1} onChange={handleChange1} textColor="primary" indicatorColor="primary">
                            {types
                              .filter((type) => ["cfg", "mps"].includes(type))
                              .sort((a, b) => a.localeCompare(b))
                              .map((type, index) => {
                                let label;

                                if (type === "cfg") {
                                  label = "Configurations";
                                } else if (type === "mps") {
                                  label = "IOT Stream";
                                }

                                return <Tab key={index} label={<Typography color="primary">{label}</Typography>} />;
                              })}
                          </Tabs>
                        </Grid>
                        <Grid item>
                          {types
                            .filter((type) => ["cfg", "mps"].includes(type))
                            .sort((a, b) => a.localeCompare(b))
                            .map((type, index) => (
                              <TabPanel key={index} value={value1} index={index}>
                                {type === "cfg" && <ConfigViewer tag={currentTag} isTagGroupMember={isTagGroupMember} />}
                                {type === "mps" && <MpsViewer tag={currentTag} isTagGroupMember={isTagGroupMember} />}
                              </TabPanel>
                            ))}
                        </Grid>
                      </Box>
                    </Paper>
                  </Grid>
                )}

                {(types.includes("doc") || types.includes("img") || types.includes("info") || types.includes("like_sirti") || types.includes("source_telemetrics")) && currentTag && (
                  <Grid item xs={12}>
                    <Paper elevation={3}>
                      <Box p={1}>
                        <Grid item container justifyContent="flex-end">
                          <Tabs value={value} onChange={handleChange} textColor="primary" indicatorColor="primary">
                            {(types.includes("doc") || types.includes("img") || types.includes("info") || types.includes("like_sirti") || types.includes("source_telemetrics")) && (
                              <Tab label={<Typography color="primary">{t("history")}</Typography>} />
                            )}
                            {types
                              .filter((type) => ["doc", "img", "info", "like_sirti", "souce_telemetrics"].includes(type))
                              .sort((a, b) => {
                                const order = ["doc", "img", "info", "like_sirti", "source_telemetrics"];
                                return order.indexOf(a) - order.indexOf(b);
                              })
                              .map((type, index) => {
                                let label;

                                if (type === "info") {
                                  label = t("notes");
                                } else if (type === "doc") {
                                  label = t("documents");
                                } else if (type === "img") {
                                  label = t("images");
                                } else if (type === "like_sirti") {
                                  label = "like_sirti";
                                } else if (type === "source_telemetrics") {
                                  label = "source_telemetrics";
                                }

                                return <Tab key={index} label={<Typography color="primary">{label}</Typography>} />;
                              })}
                          </Tabs>
                        </Grid>
                        <Grid item>
                          <TabPanel value={value} index={0}>
                            <AggregatedViewer tag={currentTag} isTagGroupMember={isTagGroupMember} />
                          </TabPanel>
                          {types
                            .filter((type) => ["doc", "img", "info", "like_sirti", "source_telemetrics"].includes(type))
                            .sort((a, b) => {
                              const order = ["doc", "img", "info", "like_sirti", "source_telemetrics"];
                              return order.indexOf(a) - order.indexOf(b);
                            })
                            .map((type, index) => (
                              <TabPanel key={index + 1} value={value} index={index + 1}>
                                {type === "doc" && <DocViewer tag={currentTag} isTagGroupMember={isTagGroupMember} />}
                                {type === "img" && <ImageViewer tag={currentTag} isTagGroupMember={isTagGroupMember} />}
                                {type === "info" && <InfoViewer tag={currentTag} isTagGroupMember={isTagGroupMember} />}
                                {type === "like_sirti" && <LikeSirtiViewer tag={currentTag} isTagGroupMember={isTagGroupMember} />}
                              </TabPanel>
                            ))}
                        </Grid>
                      </Box>
                    </Paper>
                  </Grid>
                )}

                {requests && !conservSostL1 && (
                  <Grid item xs={12}>
                    <Paper elevation={3}>
                      <Box p={2}>
                        <RequestsTable requests={requests} handleCertify={handleCertify} />
                      </Box>
                    </Paper>
                  </Grid>
                )}

                {conservSostL1 && (isTagGroupMember || isTagGroupMemberReadOnly) && currentTag && (
                  <Grid item xs={12}>
                    <Paper elevation={3}>
                      <Box p={2}>
                        <CDNFileViewer
                          tag={currentTag}
                          isTagGroupMember={isTagGroupMember || isTagGroupMemberReadOnly}
                          isTagGroupMemberReadWrite={isTagGroupMember}
                          isTagGroupMemberReadOnly={isTagGroupMemberReadOnly}
                          openUploadGenDrawer={openUploadGenDrawer}
                        />
                      </Box>
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          <DocGenerator
            tag={tag}
            types={types}
            setTypes={setTypes}
            open={openDocGen}
            setOpen={setOpenDocGen}
            handleOpenCertificationSuccessful={handleOpenCertificationSuccessful}
            handleOpenCertificationError={handleOpenCertificationError}
            request={request}
            setRequest={setRequest}
          />

          <ImageGenerator
            tag={tag}
            types={types}
            setTypes={setTypes}
            open={openImageGen}
            setOpen={setOpenImageGen}
            handleOpenCertificationSuccessful={handleOpenCertificationSuccessful}
            handleOpenCertificationError={handleOpenCertificationError}
            request={request}
            setRequest={setRequest}
          />

          <InfoGenerator
            tag={tag}
            types={types}
            setTypes={setTypes}
            open={openInfoGen}
            setOpen={setOpenInfoGen}
            handleOpenCertificationSuccessful={handleOpenCertificationSuccessful}
            handleOpenCertificationError={handleOpenCertificationError}
            request={request}
            setRequest={setRequest}
          />

          {currentTag && currentTag.targets && currentTag.targets.length !== 0 && (
            <ConfigGenerator
              tag={tag}
              targets={currentTag.targets}
              types={types}
              setTypes={setTypes}
              open={openConfigurationGen}
              setOpen={setOpenConfigurationGen}
              handleOpenCertificationSuccessful={handleOpenCertificationSuccessful}
              handleOpenCertificationError={handleOpenCertificationError}
              request={request}
              setRequest={setRequest}
            />
          )}

          {conservSostL1 && (
            <CDNFileGenerator
              tag={currentTag}
              types={types}
              setTypes={setTypes}
              open={openUploadGen}
              setOpen={setOpenUploadGen}
              handleOpenCertificationSuccessful={handleOpenCertificationSuccessful}
              handleOpenCertificationError={handleOpenCertificationError}
              request={request}
              setRequest={setRequest}
            />
          )}

          <CertificationSuccessful open={openCertificationSuccessful} setOpen={setOpenCertificationSuccessful} />

          <CertificationError open={openCertificationError} setOpen={setOpenCertificationError} />
        </>
      )}
    </>
  );
};

export default Tag;
