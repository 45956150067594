// sendCertificationStreamImage
import sendCertificationStreamImage from "./sendCertificationStreamImage";

// sendDataStreamImage
import sendDataStreamImage from "./sendDataStreamImage";

// Utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const genImageRecord = async (isPublic, uid, keypair, fileByteArray, downloadURL, caption, tdr) => {
  try {
    const timestamp = unixTimestampInSeconds();

    if (tdr) {
      // Certification stream
      const data = await sendCertificationStreamImage(keypair, fileByteArray, caption, timestamp, tdr);

      if (data.txid) {
        // Data stream
        const outcome = await sendDataStreamImage(isPublic, uid, downloadURL, caption, data, timestamp, tdr);

        if (outcome.success) {
          console.log(data);
          return {
            inserted: data.inserted,
            txid: data.txid,
          };
        } else {
          return {
            error: outcome.error,
          };
        }
      } else {
        return {
          error: data.error,
        };
      }
    } else {
      // Certification stream
      const data = await sendCertificationStreamImage(keypair, fileByteArray, caption, timestamp);

      if (data.txid) {
        // Data stream
        const outcome = await sendDataStreamImage(isPublic, uid, downloadURL, caption, data, timestamp);

        if (outcome.success) {
          console.log(data);
          return {
            inserted: data.inserted,
            txid: data.txid,
          };
        } else {
          return {
            error: outcome.error,
          };
        }
      } else {
        return {
          error: data.error,
        };
      }
    }
  } catch (e) {
    console.error("Error in genImageRecord:", e.message);

    return {
      error: e.message,
    };
  }
};

export default genImageRecord;
