// React
import React, { useState, useEffect } from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// ChartJS
import { Chart as ChartJS, TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import "chartjs-adapter-date-fns";

// Color Map
import sensorColorMap from "./sensorColorMap";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";

// Material UI Date Pickers
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

// Components
import SensorDataAggregatedTable from "./SensorDataAggregatedTable";
import DataDialog from "ui-components/CertificationManagement/DataDialog";
import DatapointIntegrityInspector from "ui-components/CertificationManagement/DatapointIntegrityInspector";
import { LoadingDialog } from "ui-components/LoadingComponent";

// RecordOperations
import fetchRecordsByType from "RecordOperations/fetchRecordsByType";

// Verificator
import verifySignature from "Verificator/verifySignature";

// A ---------------------------------------------------------------------- M

ChartJS.register(TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, annotationPlugin);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Source Data",
    },
    annotation: {
      annotations: [],
    },
  },
  scales: {
    x: {
      type: "time",
    },
    y: {
      suggestedMin: 0,
      position: "left",
      title: {
        display: true,
        text: "Values",
      },
    },
    y1: {
      type: "linear",
      position: "right",
      min: 0,
      max: 1,
      title: {
        display: true,
        text: "Predictions",
      },
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};

const naturalSort = (a, b) => {
  const regex = /(\d+)|(\D+)/g;
  const aParts = a.match(regex);
  const bParts = b.match(regex);
  while (aParts.length && bParts.length) {
    const aPart = aParts.shift();
    const bPart = bParts.shift();
    const aNum = parseInt(aPart, 10);
    const bNum = parseInt(bPart, 10);
    if (isNaN(aNum) || isNaN(bNum)) {
      if (aPart > bPart) return 1;
      if (aPart < bPart) return -1;
    } else {
      if (aNum > bNum) return 1;
      if (aNum < bNum) return -1;
    }
  }
  return aParts.length - bParts.length;
};

const getRandomColor = () => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return `rgb(${r},${g},${b})`;
};

const generateAnnotations = (alarmData, sensorColor) => {
  const rgbaColor = sensorColor.replace("rgb", "rgba").replace(")", ", 0.2)");

  alarmData.sort((a, b) => a.x - b.x);
  const annotations = [];
  let start = null;

  for (let i = 0; i < alarmData.length - 1; i++) {
    if (alarmData[i].y === "0" && alarmData[i + 1].y === "1") {
      start = alarmData[i + 1].x;
    } else if (alarmData[i].y === "1" && alarmData[i + 1].y === "0") {
      if (start) {
        annotations.push({
          type: "box",
          xMin: start,
          xMax: alarmData[i].x,
          borderColor: "rgba(0, 0, 0, 0)",
          backgroundColor: rgbaColor,
          borderWidth: 1,
        });
      } else {
        annotations.push({
          type: "box",
          xMin: alarmData[i].x,
          xMax: alarmData[i + 1].x,
          borderColor: "rgba(0, 0, 0, 0)",
          backgroundColor: rgbaColor,
          borderWidth: 1,
        });
      }
    } else if (alarmData[i].y === "1" && alarmData[i + 1].y === "1") {
      if (start === null) {
        start = alarmData[i].x;
      }
      annotations.push({
        type: "box",
        xMin: start,
        xMax: alarmData[i + 1].x,
        borderColor: "rgba(0, 0, 0, 0)",
        backgroundColor: rgbaColor,
        borderWidth: 1,
      });
      start = null;
    }
  }

  return annotations;
};

const SensorDataAggregatedViewer = ({ tag, isTagGroupMember }) => {
  const { user } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(new Date(new Date().getTime() - 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date());
  const [records, setRecords] = useState([]);
  const [aiDataOutputRecords, setAIDataOutputRecords] = useState([]);
  const [record, setRecord] = useState();
  const [verification, setVerification] = useState();
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [commonMagnitudes, setCommonMagnitudes] = useState([]);
  const [selectedMagnitude, setSelectedMagnitude] = useState("");
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openVerify, setOpenVerify] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showPredictions, setShowPredictions] = useState(false);
  const [showAlarms, setShowAlarms] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);

  useEffect(() => {
    const handlePopState = () => {
      if (openVerify) {
        setOpenVerify(false);
      } else if (openView) {
        setOpenView(false);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [openVerify, openView]);

  useEffect(() => {
    const fetchData = async () => {
      // Sensor Data
      const sensorDataRecords = await fetchRecordsByType(tag.id, "sensor_data_aggregated", isTagGroupMember, startDate, endDate);
      let groupedRecords = Object.values(
        sensorDataRecords.reduce((accumulator, currentRecord) => {
          const sensorId = currentRecord.data.sensor_id;
          const magnitude = currentRecord.data.magnitude;

          if (sensorId != null) {
            if (!accumulator[sensorId]) {
              accumulator[sensorId] = { sensorId, magnitudes: [] };
            }

            const existingMagnitudeGroup = accumulator[sensorId].magnitudes.find((group) => group.magnitude === magnitude);
            if (!existingMagnitudeGroup) {
              accumulator[sensorId].magnitudes.push({
                magnitude,
                measure_unit: currentRecord.data.measure_unit,
                records: [currentRecord],
              });
            } else {
              existingMagnitudeGroup.records.push(currentRecord);
            }
          }

          return accumulator;
        }, {})
      );

      groupedRecords = groupedRecords.filter((sensor) => sensor.sensorId != null);
      groupedRecords.forEach((sensor) => {
        sensor.magnitudes.forEach((magnitudeGroup) => {
          magnitudeGroup.records.sort((a, b) => a.data.timestamp_end - b.data.timestamp_end);
        });
      });

      setRecords(groupedRecords);

      // AI Output
      const aiDataOutputRecords = await fetchRecordsByType(tag.id, "ai_data_out", isTagGroupMember, startDate, endDate);
      const aiDataBySensor = aiDataOutputRecords.reduce((acc, record) => {
        const { sensor_id } = record.data;
        if (!acc[sensor_id]) {
          acc[sensor_id] = [];
        }

        acc[sensor_id].push(record);
        return acc;
      }, {});

      Object.keys(aiDataBySensor).forEach((sensor_id) => {
        aiDataBySensor[sensor_id].sort((a, b) => new Date(b.data.timestamp) - new Date(a.data.timestamp));
      });

      setAIDataOutputRecords(aiDataBySensor);

      setIsLoading(false);
    };

    fetchData();
  }, [tag, isTagGroupMember, startDate, endDate]);

  useEffect(() => {
    const allMagnitudes = new Set();
    selectedSensors.forEach((sensorId) => {
      const sensor = records.find((record) => record.sensorId === sensorId);
      sensor?.magnitudes.forEach((magnitude) => {
        allMagnitudes.add(magnitude.magnitude);
      });
    });
    setCommonMagnitudes([...allMagnitudes]);
  }, [selectedSensors, records]);

  useEffect(() => {
    if (!selectedMagnitude || selectedSensors.length === 0) {
      return;
    }

    const annotations = [];
    const datasets = selectedSensors
      .map((sensorId) => {
        const sensorData = records.find((record) => record.sensorId === sensorId);
        const aiData = aiDataOutputRecords[sensorId] || [];

        const sensorColor = sensorColorMap[sensorId] || getRandomColor();

        const sensorDataset = {
          label: `${sensorId} Values`,
          data:
            sensorData?.magnitudes
              .find((m) => m.magnitude === selectedMagnitude)
              ?.records.map((record) => ({
                x: record.data.timestamp_end * 1000,
                y: record.data.avg,
              })) || [],
          borderColor: sensorColor,
          backgroundColor: "rgba(0, 0, 0, 0)",
          type: "line",
        };

        const aiDataset = showPredictions
          ? {
              label: `${sensorId} Prediction`,
              data: aiData.map((record) => ({
                x: record.data.windowEnd * 1000,
                y: record.data.prediction,
              })),
              borderColor: sensorColor,
              backgroundColor: "rgba(0, 0, 0, 0)",
              type: "line",
              yAxisID: "y1",
            }
          : null;

        const alarmDataset = showAlarms
          ? {
              label: `${sensorId} Alarm`,
              data: aiData.map((record) => ({
                x: record.data.windowEnd * 1000,
                y: record.data.alarm,
              })),
              borderColor: sensorColor,
              backgroundColor: "rgba(0, 0, 0, 0)",
              stepped: true,
              type: "line",
              yAxisID: "y1",
            }
          : null;

        if (showAlarms && alarmDataset) {
          annotations.push(...generateAnnotations(alarmDataset.data, sensorColor));
        }

        return [sensorDataset, aiDataset, alarmDataset].filter(Boolean);
      })
      .flat();

    options.plugins.annotation.annotations = annotations;

    setChartData({
      datasets,
    });
  }, [selectedSensors, selectedMagnitude, records, aiDataOutputRecords, showPredictions, showAlarms]);

  const handleChangeSensors = (event) => {
    const value = event.target.value;
    if (value.includes("all")) {
      if (selectedSensors.length === records.length) {
        setSelectedSensors([]);
        setChartData({ labels: [], datasets: [] });
      } else {
        const allSensorIds = records.map((record) => record.sensorId);
        setSelectedSensors(allSensorIds);
      }
    } else {
      setSelectedSensors(value);
      if (value.length === 0) {
        setChartData({ labels: [], datasets: [] });
      }
    }
  };

  const handleChangeSelectedMagnitude = (event) => {
    setSelectedMagnitude(event.target.value);
  };

  const handleOpenVerify = () => {
    setOpenVerify(true);
    window.history.pushState(null, "");
  };

  const handleOpenView = () => {
    setOpenView(true);
    window.history.pushState(null, "");
  };

  const checkVerification = async (record) => {
    try {
      setVerificationLoading(true);

      let verification;

      if (user) {
        verification = await verifySignature(user.uid, record, record.type);
      } else {
        verification = await verifySignature(false, record, record.type);
      }

      setVerification(verification);

      handleOpenVerify();
    } catch (e) {
      console.error("Error in checkVerification:", e.message);
    } finally {
      setVerificationLoading(false);
    }
  };

  const handleView = (record) => {
    handleOpenView();
    setRecord(record);
  };

  return (
    <>
      {isLoading ? (
        <Grid item container justifyContent="center" mt="30%">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {verificationLoading && <LoadingDialog open={verificationLoading} />}

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    <b>{t("sensor_data")}</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker label={t("start_date")} value={startDate} onChange={(date) => setStartDate(date)} />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker label={t("end_date")} value={endDate} onChange={(date) => setEndDate(date)} />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Select
                      multiple
                      displayEmpty
                      value={selectedSensors}
                      onChange={handleChangeSensors}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return t("select_sensors");
                        }
                        if (selected.length === records.length) {
                          return t("all_sensors");
                        }
                        return selected.join(", ");
                      }}
                    >
                      <MenuItem value="all">
                        <Checkbox checked={selectedSensors.length === records.length} indeterminate={selectedSensors.length > 0 && selectedSensors.length < records.length} />
                        <ListItemText primary={t("select_all")} sx={{ color: "gray" }} />
                      </MenuItem>
                      {records
                        .sort((a, b) => naturalSort(a.sensorId, b.sensorId))
                        .map((sensor) => (
                          <MenuItem key={sensor.sensorId} value={sensor.sensorId}>
                            <Checkbox checked={selectedSensors.indexOf(sensor.sensorId) > -1} />
                            <ListItemText primary={sensor.sensorId} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Select fullWidth value={selectedMagnitude} onChange={handleChangeSelectedMagnitude} displayEmpty inputProps={{ "aria-label": "Select Magnitude" }}>
                    <MenuItem value="" disabled>
                      {t("select_magnitude")}
                    </MenuItem>
                    {commonMagnitudes.map((magnitude) => (
                      <MenuItem key={magnitude} value={magnitude}>
                        {magnitude}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <Grid item container xs={12} justifyContent="flex-end">
                <FormControlLabel control={<Checkbox checked={showPredictions} onChange={(e) => setShowPredictions(e.target.checked)} />} label={t("show_predictions")} />
                <FormControlLabel control={<Checkbox checked={showAlarms} onChange={(e) => setShowAlarms(e.target.checked)} />} label={t("show_alarms")} />
              </Grid>
              <Grid item xs={12}>
                <Line options={options} data={chartData} height={isMobile ? "300vh" : "100vh"} />
              </Grid>
              {selectedSensors && selectedSensors.length !== 0 && (
                <Grid item xs={12}>
                  <SensorDataAggregatedTable
                    records={records}
                    aiDataOutputRecords={aiDataOutputRecords}
                    selectedSensors={selectedSensors}
                    checkVerification={checkVerification}
                    handleView={handleView}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          {record && <DataDialog data={{ ...record, databoxName: tag.name || "N/A" }} open={openView} onClose={() => setOpenView(false)} />}

          {verification && <DatapointIntegrityInspector verification={verification} open={openVerify} setOpen={setOpenVerify} />}
        </>
      )}
    </>
  );
};

export default SensorDataAggregatedViewer;
