// React
import React from "react";
import { Link } from "react-router-dom";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

// A ---------------------------------------------------------------------- M

const TagToolbarPublic = ({ tag }) => {
  const { t } = useTranslation();

  return (
    <Box p={{ xs: 1 }}>
      <Paper elevation={4} sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction={{ xs: "column", md: "row" }} justifyContent="space-between" alignItems="center">
              <Typography
                variant="h4"
                fontWeight="bold"
                component={Link}
                to={`/${tag.id}`}
                sx={{
                  textDecoration: "none",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {tag.name || "N/A"}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "black",
                  px: 1,
                  py: 1,
                  fontWeight: "bold",
                }}
              >
                {t("databox")}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ borderColor: "#424242" }} />
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={1.5}>
              <Typography>
                <b>{t("id")}:</b> {tag.id}
              </Typography>
              <Typography noWrap>
                <b>{t("name")}:</b> {tag.name || "N/A"}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default TagToolbarPublic;
