// React
import React, { useState, useEffect, useCallback } from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-pdf
import { Document, Page, pdfjs } from "react-pdf";

// Material UI Components
import { Grid, Box, IconButton, Dialog, Typography, CircularProgress, useMediaQuery } from "@mui/material";

// Material UI Icons
import { KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon, KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon, CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";

// Components
import CertificationsTable from "ui-components/CertificationManagement/CertificationsTable";
import DataDialog from "ui-components/CertificationManagement/DataDialog";
import DatapointIntegrityInspector from "ui-components/CertificationManagement/DatapointIntegrityInspector";
import DocGenerator from "ui-components/DataTypeManagement/Doc/DocGenerator";
import ImageGenerator from "ui-components/DataTypeManagement/Image/ImageGenerator";
import InfoGenerator from "ui-components/DataTypeManagement/Info/InfoGenerator";
import CDNFileDetailsDialog from "ui-components/DataTypeManagement/CDNFile/CDNFileDetailsDialog";
import CDNPreservationProofDialog from "ui-components/DataTypeManagement/CDNFile/CDNPreservationProofDialog";
import { CertificationSuccessful, CertificationError } from "ui-components/ORFeedbacks";
import { LoadingDialog } from "ui-components/LoadingComponent";

// Firebase
import { db } from "config/firebase";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";

// UserOperations
import fetchSeenTags from "UserOperations/fetchSeenTags";
import fetchSignatures from "UserOperations/fetchSignatures";

// Verificator
import verifySignature from "Verificator/verifySignature";

// A ---------------------------------------------------------------------- M

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MyCertifications = () => {
  const { user, seedflow, canSign, conservSostL1, APIKey } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [seentags, setSeenTags] = useState([]);
  const [signatures, setSignatures] = useState([]);
  const [record, setRecord] = useState(null);
  const [verification, setVerification] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [dialogs, setDialogs] = useState({
    docGen: false,
    imageGen: false,
    infoGen: false,
    uploadGen: false,
    view: false,
    verify: false,
    preservationProof: false,
    certificationSuccessful: false,
    certificationError: false,
  });

  const toggleDialog = (dialog, state = true) => {
    setDialogs((prev) => ({ ...prev, [dialog]: state }));
    if (state) window.history.pushState(null, "");
  };

  const fetchUserData = useCallback(async () => {
    if (!user) return;

    try {
      const [seentags, signatures] = await Promise.all([fetchSeenTags(user.uid), fetchSignatures(user.uid)]);

      setSeenTags(seentags.sort((a, b) => b.firstseen - a.firstseen));
      setSignatures(signatures.sort((a, b) => b.timestamp - a.timestamp));
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchUserData();

    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    const userdataDocRef = doc(db, "userdata", user?.uid);
    const mysigsCollectionRef = collection(userdataDocRef, "mysigs");

    const newAdditionsQueryCertifications = query(mysigsCollectionRef, where("timestamp", ">=", currentTimestampInSeconds.toString()));

    const unsubscribe = onSnapshot(newAdditionsQueryCertifications, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") fetchUserData();
      });
    });

    return unsubscribe;
  }, [user, fetchUserData]);

  useEffect(() => {
    const handlePopState = () => {
      if (dialogs.view) toggleDialog("view", false);
      if (dialogs.verify) toggleDialog("verify", false);
      if (dialogs.preservationProof) toggleDialog("preservationProof", false);
    };

    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, [dialogs.view, dialogs.verify, dialogs.preservationProof]);

  const handleView = (record) => {
    setRecord(record);
    toggleDialog("view");
  };

  const checkVerification = async (record) => {
    try {
      setVerificationLoading(true);
      const verification = await verifySignature(user?.uid || false, record, record.type);
      setVerification(verification);
      toggleDialog("verify");
    } catch (e) {
      console.error("Error in checkVerification:", e.message);
    } finally {
      setVerificationLoading(false);
    }
  };

  const checkPreservationProof = (record) => {
    setRecord(record);
    toggleDialog("preservationProof");
  };

  const handleFileClick = (file, type) => {
    if (type === "doc") setSelectedFile(file);
    if (type === "img") setSelectedImage(file);
  };

  const handleCloseDocDialog = () => {
    setSelectedFile(null);
    setCurrentPage(1);
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleCloseImageDialog = () => {
    setSelectedImage(null);
  };

  return isLoading ? (
    <Grid item container justifyContent="center" mt="30%">
      <CircularProgress />
    </Grid>
  ) : (
    <Grid item xs={12}>
      <CertificationsTable
        signatures={signatures}
        handleView={handleView}
        checkVerification={checkVerification}
        checkPreservationProof={checkPreservationProof}
        openDocGenDrawer={() => toggleDialog("docGen")}
        openImageGenDrawer={() => toggleDialog("imageGen")}
        openInfoGenDrawer={() => toggleDialog("infoGen")}
        openUploadGenDrawer={() => toggleDialog("uploadGen")}
      />

      {canSign && seedflow && (
        <>
          <DocGenerator
            tags={seentags}
            open={dialogs.docGen}
            setOpen={() => toggleDialog("docGen", false)}
            handleOpenCertificationSuccessful={() => toggleDialog("certificationSuccessful")}
            handleOpenCertificationError={() => toggleDialog("certificationError")}
          />

          <ImageGenerator
            tags={seentags}
            open={dialogs.imageGen}
            setOpen={() => toggleDialog("imageGen", false)}
            handleOpenCertificationSuccessful={() => toggleDialog("certificationSuccessful")}
            handleOpenCertificationError={() => toggleDialog("certificationError")}
          />

          <InfoGenerator
            tags={seentags}
            open={dialogs.infoGen}
            setOpen={() => toggleDialog("infoGen", false)}
            handleOpenCertificationSuccessful={() => toggleDialog("certificationSuccessful")}
            handleOpenCertificationError={() => toggleDialog("certificationError")}
          />
        </>
      )}

      {verificationLoading && <LoadingDialog open={verificationLoading} />}

      {record &&
        (conservSostL1 ? (
          <>
            <CDNFileDetailsDialog record={record} open={dialogs.view} onClose={() => toggleDialog("view", false)} />
            <CDNPreservationProofDialog
              open={dialogs.preservationProof}
              setOpen={() => toggleDialog("preservationProof", false)}
              onClose={() => toggleDialog("preservationProof", false)}
              record={record}
            />
          </>
        ) : (
          <DataDialog data={{ ...record, databoxName: record.name }} open={dialogs.view} onClose={() => toggleDialog("view", false)} handleFileClick={handleFileClick} />
        ))}

      {verification && <DatapointIntegrityInspector verification={verification} open={dialogs.verify} setOpen={() => toggleDialog("verify", false)} />}

      <CertificationSuccessful open={dialogs.certificationSuccessful} setOpen={() => toggleDialog("certificationSuccessful", false)} />

      <CertificationError open={dialogs.certificationError} setOpen={() => toggleDialog("certificationError", false)} />

      {selectedFile && (
        <Dialog open={Boolean(selectedFile)} onClose={handleCloseDocDialog}>
          <IconButton
            sx={{
              color: "red",
              justifyContent: "flex-end",
            }}
            onClick={handleCloseDocDialog}
          >
            <CloseOutlinedIcon />
          </IconButton>
          <Document file={selectedFile} onLoadSuccess={handleDocumentLoadSuccess}>
            <Page pageNumber={currentPage} width={isMobile ? 300 : 600} renderAnnotationLayer={false} renderTextLayer={false} />
          </Document>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
              <KeyboardArrowLeftOutlinedIcon />
            </IconButton>
            <Typography sx={{ mx: 2 }}>
              {currentPage} / {numPages}
            </Typography>
            <IconButton onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === numPages}>
              <KeyboardArrowRightOutlinedIcon />
            </IconButton>
          </Box>
        </Dialog>
      )}

      {selectedImage && (
        <Dialog open={Boolean(selectedImage)} onClose={handleCloseImageDialog}>
          <IconButton
            sx={{
              position: "absolute",
              top: "2%",
              right: "2%",
              zIndex: 1,
              color: "red",
            }}
            onClick={handleCloseImageDialog}
          >
            <CloseOutlinedIcon fontSize="small" />
          </IconButton>
          <img src={selectedImage} alt="img" style={{ width: "100%", height: "100%" }} />
        </Dialog>
      )}
    </Grid>
  );
};

export default MyCertifications;
