// React
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// Contexts
import { UserAuth } from "context/AuthContext";

// reactflow
import ReactFlow, { MiniMap, Controls } from "reactflow";
import "reactflow/dist/style.css";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";

// Components
import AuthorizedUsersNode from "ui-components/FlowMonitorManagement/AuthorizedUsersNode";
import AuthorizedKeysNode from "ui-components/FlowMonitorManagement/AuthorizedKeysNode";
import Databox from "ui-components/FlowMonitorManagement/Databox";
import Target from "ui-components/FlowMonitorManagement/Target";

// UserOperations
import getSeenTag from "UserOperations/getSeenTag";

// TagOperations
import getTag from "TagOperations/getTag";

// FlowMonitor
import getAuthorizedUsersData from "FlowMonitor/users/getAuthorizedUsersData";
import getAuthorizedKeysData from "FlowMonitor/keys/getAuthorizedKeysData";
import getTargetsData from "FlowMonitor/targets/getTargetsData";

// A ---------------------------------------------------------------------- M

const nodeTypes = {
  authorizedUsers: AuthorizedUsersNode,
  authorizedKeys: AuthorizedKeysNode,
  Databox: Databox,
  Target: Target,
};

const FlowMonitor = () => {
  const { user } = UserAuth();
  const { tag } = useParams();
  const { t } = useTranslation();

  const [seentag, setSeenTag] = useState();
  const [databoxName, setDataboxName] = useState();
  const [authorizedUsersData, setAuthorizedUsersData] = useState();
  const [authorizedKeysData, setAuthorizedKeysData] = useState();
  const [targetsData, setTargetsData] = useState();
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSeenTag = async () => {
      const seentag = await getSeenTag(user.uid, tag);
      const tagData = await getTag(tag);
      const tagName = tagData.name;
      setSeenTag({ ...seentag, name: tagName });
    };

    const fetchData = async () => {
      // Get databox
      const databox = await getTag(tag);
      const databoxName = databox.name ? databox.name : databox.id;
      setDataboxName(databoxName);

      // Get authorized users data
      const authorizedUsersData = await getAuthorizedUsersData(tag);
      setAuthorizedUsersData(authorizedUsersData);

      const { authorizedUsersArray, totalNumberOfCertifications } = authorizedUsersData;

      // Get authorized keys data
      const authorizedKeysData = await getAuthorizedKeysData(tag);
      setAuthorizedKeysData(authorizedKeysData);

      const { authorizedKeysArray, totalNumberOfCertificationsKeys } = authorizedKeysData;

      // Get targets data
      const targetsData = await getTargetsData(tag);
      setTargetsData(targetsData);

      const { targetsArray, enabledTargetsCount } = targetsData;

      // Calculate positions for the targets
      const targetNodes = targetsArray.map((target, index) => {
        return {
          id: `target-${index + 1}`,
          type: "Target",
          data: { target },
          position: { x: 1200, y: 750 + index * 200 },
          style: { width: 300 },
        };
      });

      // Set nodes and edges for reactflow
      setNodes([
        {
          id: "1",
          type: "authorizedUsers",
          data: { authorizedUsersArray, totalNumberOfCertifications },
          position: { x: -500, y: 500 },
          style: { width: 600 },
        },
        {
          id: "2",
          type: "authorizedKeys",
          data: { authorizedKeysArray, totalNumberOfCertificationsKeys },
          position: { x: -500, y: 1200 },
          style: { width: 600 },
        },
        {
          id: "3",
          type: "Databox",
          data: { databoxName },
          position: { x: 500, y: 950 },
          style: { width: 300 },
        },
        ...targetNodes,
      ]);

      setEdges([
        {
          id: "e1-3",
          source: "1",
          target: "3",
          sourceHandle: "a",
          targetHandle: "c",
          animated: totalNumberOfCertifications > 0,
          type: "step",
          style: {
            stroke: totalNumberOfCertifications > 0 ? "#00c853" : "#b0b8b0",
          },
        },
        {
          id: "e2-3",
          source: "2",
          target: "3",
          sourceHandle: "b",
          targetHandle: "d",
          animated: totalNumberOfCertificationsKeys > 0,
          type: "step",
          style: {
            stroke: totalNumberOfCertificationsKeys > 0 ? "#00c853" : "#b0b8b0",
          },
        },
        ...targetsArray.map((target, index) => {
          return {
            id: `e3-target-${index + 1}`,
            source: "3",
            target: `target-${index + 1}`,
            sourceHandle: "e",
            targetHandle: `handle-${target.endpoint}`,
            animated: target.enabled,
            type: "step",
            style: {
              stroke: target.enabled ? "#00c853" : "#b0b8b0",
            },
          };
        }),
      ]);
    };

    const fetch = async () => {
      await fetchSeenTag();
      await fetchData();
      setIsLoading(false);
    };

    fetch();
  }, [tag]);

  return (
    <>
      {isLoading ? (
        <Grid item container justifyContent="center" mt="30%">
          <CircularProgress />
        </Grid>
      ) : (
        <Grid item container xs={12} spacing={5}>
          {seentag && (
            <Grid item xs={12}>
              <Typography variant="h5" fontWeight="bold">
                {seentag.name}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Divider sx={{ borderBottomWidth: 1, bgcolor: "black" }} />
          </Grid>

          <Grid item container xs={12} spacing={5}>
            <Grid item xs={12}>
              <Typography variant="h5" fontWeight="bold">
                {t("monitor")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <div style={{ width: "83vw", height: "63vh" }}>
                <ReactFlow nodes={nodes} edges={edges} fitView nodeTypes={nodeTypes}>
                  <MiniMap nodeStrokeWidth={3} zoomable pannable />
                  <Controls />
                </ReactFlow>
              </div>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default FlowMonitor;
