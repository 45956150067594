// React
import React from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// Material UI Components
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// react-flagkit
import Flag from "react-flagkit";

// A ---------------------------------------------------------------------- M

// Language Option Component
const LanguageOption = ({ country, label }) => (
  <Box display="flex" alignItems="center">
    <Flag country={country} style={{ marginRight: 8 }} />
    {label}
  </Box>
);

const DefaultLayout = (props) => {
  const { language, setLanguage } = UserAuth();
  const handleLanguageChange = (lng) => {
    setLanguage(lng);
  };

  return (
    <>
      <AppBar>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <Select value={language} onChange={(e) => handleLanguageChange(e.target.value)} displayEmpty sx={{ color: "white", ".MuiOutlinedInput-notchedOutline": { border: "none" } }}>
            <MenuItem value="it">
              <LanguageOption country="IT" label="Italiano" />
            </MenuItem>
            <MenuItem value="en">
              <LanguageOption country="GB" label="English" />
            </MenuItem>
          </Select>
        </Toolbar>
      </AppBar>
      {props.children}
    </>
  );
};

export default DefaultLayout;
