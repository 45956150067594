// React
import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";

// Material UI Icons
import {
  FirstPageOutlined as FirstPageOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  LastPageOutlined as LastPageOutlinedIcon,
  Add as AddIcon,
  Edit as EditIcon,
  ToggleOff as ToggleOffIcon,
  ToggleOn as ToggleOnIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

// Components
import { ForwardRouteRemoveConfirm } from "ui-components/ORFeedbacks";

// TagOperations
import getTagOwner from "TagOperations/getTagOwner";
import disableForwardRoute from "TagOperations/disableForwardRoute";
import enableForwardRoute from "TagOperations/enableForwardRoute";
import removeForwardRoute from "TagOperations/removeForwardRoute";

// Utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const ForwardRoutesTableHead = () => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "id", label: t("id") },
      { id: "endpoint", label: t("endpoint") },
      {
        id: "timestamp",
        label: t("creation_date"),
        mobileLabel: t("creation_date"),
      },
      { id: "type", label: t("type") },
      { id: "types", label: t("datatypes") },
      { id: "status", label: t("status") },
      { id: "actions", label: t("actions") },
    ],
    [t]
  );

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.id !== "actions" ? "left" : "right"}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const ForwardRoutesTableToolbar = ({ openAddForwardRouteDrawer, tagOwner }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={5}>
        <Typography variant="h6" fontWeight="bold">
          {t("target_endpoints")}
        </Typography>
      </Grid>
      {tagOwner === user.uid && (
        <Grid item xs={12} md={7}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs={12} md={3}>
              <Button variant="contained" fullWidth onClick={openAddForwardRouteDrawer} startIcon={<AddIcon />}>
                {t("new_endpoint")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {isRtl ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {isRtl ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const ForwardRoutesTable = ({ tag, forwardRoutes, openAddForwardRouteDrawer, clickEdit, handleSuccessfulRemoved, handleErrorRemoved }) => {
  console.log(forwardRoutes);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tagOwner, setTagOwner] = useState();
  const [selectedRoute, setSelectedRoute] = useState();
  const [selectedTag, setSelectedTag] = useState();
  const [showRemove, setShowRemove] = useState(false);

  useEffect(() => {
    const fetchTagOwner = async () => {
      const tagOwner = await getTagOwner(tag.id);
      setTagOwner(tagOwner);
    };

    fetchTagOwner();
  }, [tag]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleShowRemove = (routeID, tagID) => {
    setShowRemove(true);
    setSelectedRoute(routeID);
    setSelectedTag(tagID);
  };

  const handleRemoveForwardRoute = async () => {
    try {
      await removeForwardRoute(selectedRoute, selectedTag);
      setShowRemove(false);
      handleSuccessfulRemoved();
    } catch (error) {
      handleErrorRemoved();
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - forwardRoutes.length) : 0;

  return (
    <>
      <Box>
        <ForwardRoutesTableToolbar openAddForwardRouteDrawer={openAddForwardRouteDrawer} tagOwner={tagOwner} />
        <br />
        <Divider />
        <TableContainer>
          <Table>
            <ForwardRoutesTableHead />
            <TableBody>
              {(rowsPerPage > 0 ? forwardRoutes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : forwardRoutes).map((forwardRoute) => {
                return (
                  <TableRow
                    key={forwardRoute.id}
                    sx={{
                      "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                    }}
                  >
                    <TableCell align="left">{forwardRoute.id}</TableCell>
                    {isMobile ? <TableCell align="left">{forwardRoute.endpoint || "N/A"}</TableCell> : <TableCell align="left">{forwardRoute.endpoint || "N/A"}</TableCell>}
                    {!isMobile && <TableCell align="left">{forwardRoute.added_on ? convertTimestamp(forwardRoute.added_on) : "N/A"}</TableCell>}
                    {!isMobile && <TableCell align="left">{forwardRoute.type || "N/A"}</TableCell>}
                    {!isMobile && (
                      <TableCell align="left">
                        <List dense>
                          {forwardRoute.types.map((type) => (
                            <ListItem key={type}>
                              <ListItemText primary={type} />
                            </ListItem>
                          ))}
                        </List>
                      </TableCell>
                    )}
                    <TableCell
                      sx={{
                        color: forwardRoute.enabled ? "green" : "red",
                      }}
                      align="left"
                    >
                      {forwardRoute.enabled ? t("enabled").toUpperCase() : t("disabled").toUpperCase()}
                    </TableCell>
                    <TableCell align="right" size="small">
                      <Tooltip title={t("edit")} placement="top">
                        <IconButton color="primary" onClick={() => clickEdit(forwardRoute)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={forwardRoute.enabled ? t("disable") : t("enable")} placement="top">
                        <IconButton onClick={() => (forwardRoute.enabled ? disableForwardRoute(tag.id, forwardRoute.id) : enableForwardRoute(tag.id, forwardRoute.id))}>
                          {forwardRoute.enabled ? <ToggleOnIcon sx={{ color: "green" }} /> : <ToggleOffIcon sx={{ color: "red" }} />}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("remove")} placement="top">
                        <IconButton sx={{ color: "red" }} onClick={() => handleShowRemove(forwardRoute.id, tag.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
            {!isMobile && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                    colSpan={7}
                    count={forwardRoutes.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("rows_per_page")}
                    labelDisplayedRows={({ from, to, count }) =>
                      t("displayed_rows", {
                        from,
                        to,
                        count: count !== -1 ? count : `more than ${to}`,
                      })
                    }
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
          {isMobile && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <TablePaginationActions count={forwardRoutes.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />
            </Box>
          )}
        </TableContainer>
      </Box>

      <ForwardRouteRemoveConfirm showRemove={showRemove} setShowRemove={setShowRemove} handleRemoveForwardRoute={handleRemoveForwardRoute} />
    </>
  );
};

ForwardRoutesTable.propTypes = {
  tag: PropTypes.object.isRequired,
  forwardRoutes: PropTypes.array.isRequired,
  openAddForwardRouteDrawer: PropTypes.func.isRequired,
  clickEdit: PropTypes.func.isRequired,
  handleSuccessfulRemoved: PropTypes.func.isRequired,
  handleErrorRemoved: PropTypes.func.isRequired,
};

export default ForwardRoutesTable;
