// Firebase
import { db } from "config/firebase";
import { doc, collection, getCountFromServer } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const getTagRecordsCount = async (tag) => {
  try {
    const tagsdataDocRef = doc(db, "tagsdata", tag);
    const recordsRef = collection(tagsdataDocRef, "signatures");
    const snapshot = await getCountFromServer(recordsRef);

    return snapshot.data().count || 0;
  } catch (error) {
    console.error(`Error in getTagRecordsCount for tag ${tag}:`, error.message);
    return null;
  }
};

export default getTagRecordsCount;
